<template>
    <v-dialog 
        scrollable
        persistent
        fullscreen 
        transition="dialog-bottom-transition"
        v-model="openDialogMediatheque"
        class="mediathequePopup"
         >
         <div v-if="loading" class="loaderUpload">
            <v-progress-circular
                :size="50"
                :width="7"
                color="primary"
                indeterminate
                ></v-progress-circular>
        </div>
         <div class="dropDiv" v-show="dragover"
             @drop.prevent="onDrop($event)"
             @dragleave.prevent="dragover = false"
             @dragenter.prevent="dragover = true"
                @dragover.prevent="dragover = true">
            <div v-if="multiple">{{$t('DropYourFilesLabel')}}</div>
            <div v-else>{{$t('DropYourFileLabel')}}</div>
        </div>
        <v-card tile 
                @dragenter.prevent="dragover = true"
                @dragover.prevent="dragover = true"
                >
           <v-toolbar flat dark class="bg-gradient">
                <v-btn
                depressed
                color="white"
                class="square mr-2"
                text
                @click.native="closeDialog"
            >
                <v-icon dark small>$prev</v-icon>
            </v-btn>
        
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                {{$t('cancelLabel')}}
            </v-btn>
            <v-btn depressed outlined color="white" @click="saveUrl">
                {{$t('selectMediaLabel')}}
            </v-btn>
            </v-toolbar>
            <v-card-text
                v-scroll.self="scrollTest"
                >
                
                <v-row v-if="errorFile != ''">
                    <v-col cols="12" class="error--text errorDiv text-center pb-0 mt-2">{{$t(errorFile)}}</v-col>
                </v-row>
                <v-row style="margin-bottom:50px; position:relative;" id="innerMedia">
                    <v-col cols="12" style="padding-top:25px;">
                        <v-text-field v-model="searchString" @input="launchSearch" hide-details :placeholder="$t('search')" filled flat dense solo class="mb-1 mt-1 input-form"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="filters pt-8" v-if="availableFilters.length > 0">
                        <v-autocomplete
                            v-for="(availableFilter, index) in availableFilters" 
                            :key="index"
                            v-show="!prefilters || !prefilters[availableFilter.ref]"
                            @change="loadMedias"
                            v-model="filters[availableFilter.ref]"
                            :items="metas[availableFilter.ref]"
                            item-text="value"
                            item-value="value"
                            class="mx-3"
                            multiple
                            :label="availableFilter.label"
                            filled
                            dense
                            max-width="400"
                            clearable
                            :disabled="!metas[availableFilter.ref] || !metas[availableFilter.ref].length"
                            :no-data-text="$t('no-data-text')"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="thumbGrid" :class="(availableFilters.length == 0 && errorFile == '') ? 'mt-4' : ''">
                        <div v-if="this.upload" class="thumbnail my-1 mx-0" @click="$refs.uploaderFilesInput.click()">
                            <div class="upload">
                                <v-icon x-large>$paperclip_l</v-icon>
                            </div>
                        </div>
                        <div v-for="media in medias" :key="media.id" :class="selectedMedias.some(m => m.url === media.url) ? 'thumbnail active my-1 mx-0' : 'thumbnail my-1 mx-0'" @click="selectMedia(media)">
                            <div class="activeHover">
                                <v-icon x-large dark>$check_s</v-icon>
                            </div>
                            <!-- <embed v-if="media.mime === 'application/pdf'" :src="media.url + '#zoom=35'" width="300" height="200" /> -->
                            <v-img :src="media.thumb" contain width="300" height="200" style="border: 2px solid #CCC"></v-img>
                            <p class="text-center mt-2 mb-0" style="max-width:300px;">{{media.title}}</p>
                        </div>
                        <div class="thumbnail transparent" v-show="supElement != 0" v-for="element in supElement" :key="element">
                            <div class="upload"></div>
                        </div>
                    </v-col>
                </v-row>
                <input
                    ref="uploaderFilesInput"
                    class="d-none"
                    type="file"
                    :multiple="multiple"
                    @change="onFileChange"
                    :accept="accept ? accept : ''"
                >
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"MediathequeDialogComponent",
    props:['openDialogMediatheque', 'value', "prefilters", "multiple", "upload", "availableFilters", "returnUrl", "accept"],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            dialogUpload:false,
            title:'Sélectionner un média', 
            filters: {}, 
            filtersForUpload: {},
            metas: {}, 
            metasUpload: {},
            medias: [],
            loading:false,
            files:[],
            selectedMedias: [],
            uploadMediaButton:false,
            selectedUpload: "",
            dragover:false,
            uploadedFiles: [],
            errorFile: "",
            modulo:0,
            supElement:0,
            from:0,
            limit:30,
            searchString: ""
        }
    },
    mounted() {
        if(this.prefilters) {
            this.filters = Object.assign({}, this.prefilters);
        }
        this.loadMedias();
        if(this.returnUrl) {
            this.selectedMedias = this.value ? (this.multiple ? this.value.map(function(media) { return {"url": media}; }) : [{"url": this.value}]) : [];
        } else {
            this.selectedMedias = this.value ? (this.multiple ? this.value : [this.value]) : [];
        }
    },
    computed: {
        mediaUrl(){
            return this.$store.state.auth.currentUser.accountParameters.mediaUrl
        },
        apiKey(){
            return this.$store.state.auth.currentUser.accountParameters.mediaApiKey
        },
    },
    watch: {
        openDialogMediatheque(val){
            this.loadMedias() 
        }
    },
    methods: {
        launchSearch(){
            if(!this.loading){
                setTimeout(() => {
                    this.loading= true,
                    this.from = 0,
                    this.loadMedias();
                }, 200);
            }

        },
        closeDialog(){
            this.from = 0
            this.$emit('close')
        },
        scrollTest($evt){
            var elementTop = document.getElementById('innerMedia').getBoundingClientRect().top;
            var elementHeight = document.getElementById('innerMedia').getBoundingClientRect().height;
            var outerSize = $evt.srcElement.clientHeight
            if(elementTop < (outerSize - elementHeight + 100) && this.loading == false){
                this.loading = true;
                this.from = this.from + this.limit;
                this.loadMedias(true);
            }
                
            
        },
        getModuloSize(){
            this.modulo = Math.floor(window.innerWidth / 300)
            this.supElement = this.modulo - (this.medias.length + 1)%this.modulo
        },
        onDrop(event){
            this.dragover = false;
            this.files = [];
            event.dataTransfer.files.forEach(element => {
                this.files.push(element)
            });
            if(!this.multiple && this.files.length > 1){
                this.errorFile = "noMoreThanOneFileError"
                this.files = [];
            } else {
                this.errorFile = ""
                this.onUploadMedia() 
            }
        },
        loadMedias(add = false) {
            let jsonData = {
                's': this.searchString,
                'meta': this.filters,
                'from': this.from,
                'limit': this.limit
            };
            GenericDataService.postData(this.mediaUrl + "search?key=" + this.apiKey, jsonData).then((response) => {
                this.loadFilters(response.data.aggregations);
                this.loadUploadFilters(response.data.lists);
                if(add){
                    this.medias = this.medias.concat(response.data.medias);
                } else {
                    this.medias = response.data.medias;
                }
                    

                this.loading= false
                this.getModuloSize()
                this.$forceUpdate()
            });
        },
        loadUploadFilters(lists){
            if(this.availableFilters && this.availableFilters.length > 0){
                this.availableFilters.forEach(filter => {
                    let ref = filter.ref
                    this.metasUpload[ref] = lists[ref]
                });
            }
        },
        loadFilters(metas) {
            if(this.availableFilters && this.availableFilters.length > 0){
                this.availableFilters.map(function(availableFilter) {
                    let meta = metas.find((e) => e.field.reference == availableFilter.ref)
                    this.metas[availableFilter.ref] = meta && meta.items ? meta.items : [];
                }, this);
            }
        },
        selectMedia(media){
            if(this.multiple) {
                this.selectedMedias.some(m => m.url === media.url) ? 
                    this.selectedMedias.splice(this.selectedMedias.findIndex(m => m.url === media.url), 1) : this.selectedMedias.push(media);
            } else {
                this.selectedMedias = 
                    this.selectedMedias.some(m => m.url === media.url) ? [] : [media];
            }
        },
        onFileChange() {
            this.$refs.uploaderFilesInput.files.forEach(element =>
                this.files.push(element)
            );
            this.errorFile = ""
            this.onUploadMedia()
        },
        onUploadMedia() {
            this.loading= true
            if (this.files && this.files[0]) {
                this.uploadMediaButton = true
                this.errorFile = ""
                let filtersToUpload = Object.assign({}, this.filtersForUpload, this.filters)
                // TODO => Mettre validation du fichier
                const reader = new FileReader
                const vm = this
                reader.onload = e => {
                    let jsonData = {
                        'title': vm.files[0].name,
                        'meta': filtersToUpload,
                        'base64': e.target.result
                    };
                    GenericDataService.postData(this.mediaUrl + "upload?key=" + this.apiKey, jsonData).then((response) => {
                        this.medias.unshift(response.data.media);
                        this.selectMedia(response.data.media);
                        this.filtersForUpload = {};
                        this.dialogUpload = false;
                        this.uploadMediaButton = false;
                        this.files = [];
                        this.errorFile = ""
                        this.loading= false
                    //    setTimeout(() => {
                    //         this.loadMedias()
                    //         this.getModuloSize()
                    //         this.loading= false
                    //    }, 500); 
                        
                    });
                }
                reader.readAsDataURL(this.files[0])
            } else {
                this.errorFile = this.$t("selectImageError")
            }
        },
        closeUpload() {
            this.filtersForUpload = {};
            this.errorFile = ""
            this.dialogUpload = false;
        },
        saveUrl() {
            //this.$emit('input',(this.multiple ? [] : ""))
            if(this.multiple) {
                if(this.returnUrl) {
                    this.$emit('input', this.selectedMedias.map(function(media) { return media.url+"?t="+Date.now(); }));
                } else {
                    this.$emit('input', this.selectedMedias);
                }
            } else {
                if (this.returnUrl) { 
                    this.$emit('input', this.selectedMedias && this.selectedMedias.length ? this.selectedMedias[0].url+"?t="+Date.now() : "");
                } else {
                    this.$emit('input', this.selectedMedias && this.selectedMedias.length ? this.selectedMedias[0] : {});
                }
            }

            // Remise à zéro du composant
            this.selectedMedias = [];
            this.filters = this.prefilters ? Object.assign({}, this.prefilters) : {};
            this.loadMedias();
            this.files = [];
            this.$emit('close');
        },
    },
}
</script>
<style lang="scss">
.dropDiv {
        position:absolute;
        height:100%;
        width:100%;
        background-color:rgba(0,0,0,0.5);
        z-index:1200;
        display:flex;
        align-items: center;
        justify-content: center;
        div{
            font-size:34px;
            pointer-events:none;
            color:white
        }
    }

    .errorDiv {
        font-size:24px;
        line-height: 32px;
    }
    .file-input {
        display: none
    }
    .cardText {
        position:relative;
    }
    .loaderUpload {
        width: 100%;
        height: 100%;
        position:absolute;
        display:flex;
        justify-content: center;
        align-items: center;
        z-index:1000;
        background-color:rgba(255,255,255,0.7)
    }
    .hoverCard {
        position:absolute;
        pointer-events: none;
        height:90%;
        width:90%;
        top:5%;
        left:5%;
        display:flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed var(--v-primary-base) !important;
        background-color: rgba(255,255,255,0.7);
        z-index: 1000;
        div {
            font-size:24px;
            font-weight: bold;
        }
    }
    .required {
    label.v-label:after {
      content: '*';
      color: var(--v-error-base);
      margin-left: 5px;
    }
  }
    .mediathequeFooter {
        z-index: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #FFF;
        height:80px;
        display:flex;
        align-items:center;
        justify-content: flex-end;
        border-top: 1px solid #CCC;
    }
    .filters {
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .thumbGrid {
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        .thumbnail {
            position:relative;
            border: 2px solid transparent;
            cursor: pointer;
            .activeHover {
                position:absolute;
                width:100%;
                height:100%;
                display:flex;
                align-items: center;
                justify-content: center;
                background-color:var(--v-primary-base) !important;
                opacity:0;
                z-index:1;
            }
            .upload {
                width:300px;
                height:200px;
                display:flex;
                align-items: center;
                justify-content: center;
                background-color: lightgrey !important;
                opacity:1;
            }
            &.transparent {
                pointer-events: none;
                .upload {
                    background-color: transparent !important;
                }
                &:hover {
                    border: none !important;
                }
            }
            &:hover {
                border: 2px solid var(--v-primary-base) !important;
            }
            &.active {
                border: 2px solid var(--v-primary-base) !important;
                .activeHover {
                    opacity:0.5;
                }
            }
            
        }
        
    }
</style>